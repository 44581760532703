h1 {
  font-size: 2.5rem;
  text-transform: uppercase;
  color: $headings-text;
  font-weight: 700;
}

h2 {
  font-size: 2rem;
  text-transform: uppercase;
  color: $headings-text;
  font-weight: 700;
}

h3 {
  font-size: 1.75rem;
  text-transform: capitalize;
  color: $headings-text;
  font-weight: 700;
}

h4 {
  font-size: 1.5rem;
  text-transform: capitalize;
  color: $headings-text;
  font-weight: 700;
}

h5 {
  font-size: 1.25rem;
  text-transform: capitalize;
  color: $headings-text;
  font-weight: 700;
}

h6 {
  font-size: 1.2rem;
  text-transform: capitalize;
  color: $headings-text;
  font-weight: 700;
}

p {
  font-size: 1rem;
}

a {
  font-size: 1rem;
  text-decoration: none;
}

@font-face {
  font-family: 'OpenSans';
  src: local('OpenSans-Bold'),
    url('./fonts/OpenSans-Bold.ttf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'OpenSans';
  src: local('OpenSans-Medium'),
    url('./fonts/OpenSans-Medium.ttf') format('truetype');
  font-weight: 500;
}
