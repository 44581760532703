.SeparatorRoot {
    margin: auto;
}
.SeparatorRoot[data-orientation='horizontal'] {
  height: 1px;
  width: 80%;
}
.SeparatorRoot[data-orientation='vertical'] {
  height: 80%;
  width: 1px;
}

.Text {
  color: white;
  font-size: 15px;
  line-height: 20px;

}