$border-radius: 0.25rem;
$dark-theme: true;
$contrast: #5562f7;
$primary: #ffffff;
$secondary: #1c1d21;
$info: #717c8e;
$success: #77c188;
$warning: #35292c;
$error: #bc6969;
$body-bg: #0e0915;
$border-grey: #495057;
$headings-text: #e4e7eb;
$component-bg: #04061b;
$base-text: #e4e7eb;
$base-bg: rgba(44, 48, 92, 0.22);
$series-a: #f4f4b1;
$series-b: #3392ff;
$series-c: #ffcd00;
$series-d: #00ffa2;
$series-e: #e16100;
$series-f: #ffa5c0;
$series-g: #1f51ff;
$series-h: #9facbb;
// $hovered-text: #5562f7;
$hovered-bg: $series-g;
$selected-text: $primary;
$selected-bg: $series-b;
$button-text: #717c8e;
$button-bg: #1c1d21;
$link-text: $primary;
$link-hover-text: #7b5f27;

@import '~@progress/kendo-theme-bootstrap/dist/all.scss';
@import '~bootstrap/scss/bootstrap.scss';

.k-dropdown {
  cursor: pointer;
}

span.k-dropdown-wrap span.k-input strong {
  color: $primary;
}

.k-dropdown .k-dropdown-wrap .k-icon {
  color: $primary;
}

.k-state-empty .k-label {
  color: transparent;
}

span.number strong {
  color: $primary;
}

.k-label {
  .k-textbox,
  .k-widget {
    margin-left: 2rem;
  }
}

.k-textbox.small {
  width: 4em;
}
.k-textbox.medium {
  width: 8em;
}

.k-widget.k-dropdown.small {
  width: 4em;
}
.k-widget.k-dropdown.medium {
  width: 8em;
}
.k-widget.k-dropdown.normal {
  width: 12.4em;
}
.k-widget.k-dropdown.large {
  width: 20em;
}

.k-button-group .k-button {
  padding: 1em 2em;
}

.k-button.k-state-selected {
  color: $primary;
}

.k-selected {
  background-color: #247cff !important;
}

.k-popup {
  border-color: #4b5056;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

.k-list {
  background-color: #313438 !important;
}

.k-list-item:hover {
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0.1);
}
