@import './variables';
@import './blockscholes_theme/blockscholes_bootstrap';
@import './bootstrap';
@import './typography';
// @import './kendo';

html {
  font-size: 14px;
}

button,
fieldset {
  all: unset;
}

[role='group'] > svg,
[type='button'],
[role='group'] > button > svg {
  -webkit-appearance: none !important;
}

body {
  font-family: $font-family;
  background-color: $body-bg;
  background: radial-gradient(ellipse at bottom, #11233e, #050c15),
    radial-gradient(ellipse at top, #000000, #11233e);
}

.add-new .k-select {
  display: none;
}

a:hover {
  text-decoration: none;
}

@import '~@progress/kendo-theme-default/dist/all.scss';

.clickable {
  cursor: pointer;
  text-decoration: underline;
}

.scroll-fix {
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: overlay;
}

.k-menu-group .k-item {
  font-family: 'OpenSans';
}
.k-menu-group .k-item > .k-link:hover {
  background-color: $contrast;
  filter: brightness(140%);
}
.k-menu-group .k-item > .k-link:active {
  background-color: $contrast;
  filter: brightness(150%);
}

.k-loader-primary {
  color: $contrast !important;
}

.offset-y {
  transform: translateY(-200px);
}

// TODO: move these out of main.scss
.k-input-value-text {
  text-transform: capitalize;
}
.k-time-separator {
  display: none !important;
}

.nav-live-feed {
  // margin: auto !important;
  @media (max-width: 1130px) {
    display: none;
  }

  .k-link {
    height: 0 !important;
    padding: 0 !important;
  }
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner {
  z-index: 1;
  width: 90px;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  overflow: hidden;
  position: relative;
}

.spinner::before,
.spinner::after {
  content: ' ';
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 15px solid transparent;
  mix-blend-mode: overlay;
  animation: spinner var(--duration) var(--timing) infinite;
  pointer-events: none;
}

.spinner::before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  width: 80%;
  height: 80%;
  border-radius: 50%;
  border: 10px solid transparent;
  border-top-color: #2d2f33;
  border-right-color: #2d2f33;

  animation: spinner 1.2s ease-in-out infinite;
  // --duration: 2s;
  // --timing: ease-in-out;
}

.spinner::after {
  content: '';
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 10px solid transparent;
  border-bottom-color: #2d2f33;
  animation: spinner 1.5s ease-out infinite;
}

.only-desktop {
  height: 100vh;
  @media (max-width: 710px) {
    display: none;
  }
}
.do-not-support-width {
  display: none;
  @media (max-width: 710px) {
    display: flex;
  }
}

.price-bar-wrapper {
  font-size: 14px;
  height: 80px;
  padding: 0.25rem;
  display: flex;
  transform: scale(0.75);

  div {
    // border-bottom: 1px solid $border-grey;
    border-top: 1px solid $border-grey;
  }

  div:nth-child(odd) {
    background: $body-bg;
  }
}

table {
  width: 100%;

  .table-row {
    height: 1.5rem;
    td {
      text-align: center;
      border-left: 0.5px solid rgb(25, 58, 98);
      border-right: 0.5px solid rgb(25, 58, 98);
    }
    &:hover {
      filter: brightness(140%);
      background: rgba(44, 48, 92, 0.32);
    }
  }
  // .table-row:last-child {
  //   border-bottom: 0.5px solid rgb(25, 58, 98);
  // }

  .table-row:nth-child(odd) {
    background: rgba(44, 48, 92, 0.12);
    &:hover {
      filter: brightness(140%);
      background: rgba(44, 48, 92, 0.22);
    }
  }

  .table-head {
    tr > th {
      height: 1rem;
      padding: 0;
      padding-top: 0.25rem;
      border: 0.5px solid rgb(25, 58, 98);
    }
  }

  i {
    margin: 0;
    padding: 0;
  }
}

th.group {
  background-color: rgb(5, 24, 47);
  border: 0.5px solid rgb(25, 58, 98);
}

input[type='number']::placeholder {
  color: white;
}

.ListItemEven,
.ListItemOdd {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ListItemEven {
  background-color: #e6e4dc;
}

.ListItemOdd {
  background-color: #cdc296;
}

.drag-handle {
  &::after {
    border-bottom: 2px solid white;
    border-right: 2px solid white;
    bottom: 6px;
    content: '';
    height: 5px;
    position: absolute;
    right: 8px;
    width: 5px;
  }
}

#live {
  animation: circle-pulse 0.7s;
  animation-iteration-count: infinite;
}

@keyframes circle-pulse {
  0% {
    opacity: 0.75;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    filter: brightness(1.4);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    opacity: 0.95;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
